<!--
 * @version: 20220211
 * @Author: zgy
 * @Date: 2022-08-03 14:31:06
 * @LastEditors: zgy
 * @LastEditTime: 2022-08-03 17:55:51
 * @Description: description
-->
<template>
  <div class="page-box">
    <div class="notice-details">
      <div class="notice-title">{{formData.noticeTitle||''}}</div>
      <div class="time">{{formData.timeStr||''}}</div>
      <div class="notice-cont">    
        <!-- 详情图  -->
        <div class='goodsDetail' v-html="formData.noticeContent">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { getNoticeDetail } from '@/api/notice'
export default {
  name:'noticedetail',
  setup() {
    const route = useRoute()

    const state = reactive({
      formData: {},
      noticeId: null,
      buttonClicked: false
    })

    onMounted(async() => {
      const { id:noticeId } = route.params
      if (!noticeId) return;
      const { data } = await getNoticeDetail(noticeId)
      state.noticeId = noticeId;
      data.timeStr = data.createTime.substring(0, 10)
      state.formData = data
    })


    return {
      ...toRefs(state)
    }
  }
};
</script>

<style lang="less" scoped>
  .page-box{
    min-height: 100vh;
    background-color: #FFFFFF;
  }
.notice-details{
  padding: 15px;
  .notice-title{
    text-align: center;
    font-size: 18px;
    color: #333333;
  }
  .time{
    text-align: right;
    font-size: 13px;
    color: #999999;
    margin: 15px 0 20px;
  }
  .goodsDetail{
    font-size: 14px;
  }
}
</style>